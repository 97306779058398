import React from "react";

const UsecaseInfo = () => {

  
  return(
    <section className="usecase-info">
      <div className="w-4/5 mx-auto">
        <p className="text-rhino-950 md:text-2xl	font-medium">オーダーメイドのスタンプラリーで</p>
        <h3 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold py-3">さまざまなご要望にお応えします</h3>
      </div>
        <div className="w-full m-auto py-10 relative">
          <ul className="usecase-list">
            <li className="usecase-01">
              <h4>周遊促進</h4>
              <div className="usecase-text usecase-text-01">
                <p>
                  有名な観光地だけでなく、<br />
                  <span className="font-bold">あまり知られていない地域</span><br />
                  にも来てほしい
                </p>
                <p>
                人流を分散して、<br />
                <span className="font-bold">オーバーツーリズム</span>を解消したい
                </p>
              </div>
              <div className="usecase-image">
                  <img src={`${process.env.PUBLIC_URL}/images/usecase-01.png`} alt="" />
              </div>
            </li>
            <li className="usecase-02">
              <h4>イベント<br />運営</h4>
              <div className="usecase-text usecase-text-02">
                <p>
                  場所によって、<br />
                  <span className="font-bold">スタンプの取得方法</span>を<br />
                  変えたい
                </p>
                <p>
                  キャンペーンの<br />
                  <span className="font-bold">運営が大変</span><br />
                  全面的にサポートしてほしい
                </p>
              </div>
              <div className="usecase-image">
                <img src={`${process.env.PUBLIC_URL}/images/usecase-02.png`} alt="" />
              </div>
            </li>
            <li className="usecase-03">
              <h4>観光資源の<br />発見</h4>
              <div className="usecase-text usecase-text-03">
                <p>
                  参加者の声を集めて<br />
                  さらに<span className="font-bold">集客</span>につなげたい
                </p>
                <p>
                  イベントをきっかけに、<br />
                  地域の<span className="font-bold">新しい<br />観光資源</span>を見つけたい
                </p>
              </div>
              <div className="usecase-image">
                <img src={`${process.env.PUBLIC_URL}/images/usecase-03.png`} alt="" />
              </div>
            </li>
          </ul>
        </div>
        <div className="absolute -bottom-12 left-[calc((100%-5px)/2)] z-10">
          <img src={`${process.env.PUBLIC_URL}/images/three-dots.png`} alt="" />
        </div>
    </section>
  )
}

export default UsecaseInfo;