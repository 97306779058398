import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const Header = () => {
  const [openMenu, setOpenMenu] = useState(true);
  const [imageUrl, setImageUrl] = useState('/images/logo.png');

  const handleMenuOpen = () => {
    setOpenMenu(!openMenu);
  };

  const handleResize = () => {
    const isMobile = window.innerWidth <= 1024;
    setOpenMenu(!isMobile);
  };
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = () => {
    setImageUrl('/images/back-to-home.png');
  };

  const handleMouseLeave = () => {
    setImageUrl('/images/logo.png');
  };

  return(
    <header className="global-navigation-area bg-white fixed w-full z-20 top-0 start-0 border-b border-gray-200">
      <nav className="border-gray-200">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse logo-wrapper"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img src={`${process.env.PUBLIC_URL}${imageUrl}`} alt="" className="logo" />
          </Link>
          <button
            onClick={handleMenuOpen}
            type="button"
            className={`inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 ${openMenu ? "fade-in-out" : ""}`}
          >
            <span className="sr-only">Open main menu</span>
            {openMenu ? (
              // X マークの SVG アイコン
              <svg className="w-5 h-5 mr-1 -mt-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            ) : (
              // ハンバーガーアイコンの SVG
              <svg className="w-5 h-5 mr-0 mt-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"></path>
              </svg>
            )}
          </button>
          <div className={openMenu ? 'block w-full lg:block lg:w-auto' : 'hidden'}>
            <ul className="text-lg flex flex-col leading-10 p-4 lg:p-0 mt-4 border border-gray-100 lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0">
              <li><Link to="/application-example">機能・活用例</Link></li>
              <li><Link to="/useflow">ご利用の流れ</Link></li>
              <li><Link to="/faq">よくあるご質問</Link></li>
              <li className="mt-4"><Link to="https://galileoscope.co.jp/contact" className="contact-button">資料請求・お問い合わせ</Link></li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header;