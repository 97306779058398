import Top from './views/pages/Top';
import ApplicationExample from './views/pages/ApplicationExample';
import UseFlow from './views/pages/UseFlow';
import Faq from './views/pages/Faq';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import ScrollToTop from './views/components/modules/ScrollToTop';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/application-example" element={<ApplicationExample />} />
          <Route path="/useflow" element={<UseFlow />} />
          <Route path="/faq" element={<Faq />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
