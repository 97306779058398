import React from "react";
import { Link } from "react-router-dom";


const ServiceNavigation = () => {



  return(
    <section className="service-navigation">
      <div className="w-4/5 mx-auto">
        <h3 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold py-3">サービスを詳しく知る</h3>
        <ul className="w-full sm:w-4/5 flex-row flex justify-center m-auto py-10">
          <li>
            <Link to="/application-example">
              <img src={`${process.env.PUBLIC_URL}/images/icon-function.png`} alt="" />
              機能・活用例
            </Link>
          </li>
          <li className=" border-slate-700 border-solid border-x-[3px]">
            <Link to="/useflow">
              <img src={`${process.env.PUBLIC_URL}/images/icon-shakehands.png`} alt="" />
              ご利用の流れ
            </Link>
          </li>
          <li>
            <Link to="https://galileoscope.co.jp/contact">
              <img src={`${process.env.PUBLIC_URL}/images/icon-headphone.png`} alt="" />
              お問い合わせ
            </Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default ServiceNavigation;