import React from "react";

const FeaturesInfo = () => {
  
  return(
    <section className="features-info">
      <div className="w-4/5 mx-auto">
          <p className="text-rhino-950 md:text-2xl	font-medium">どこでもラリーは、いままでにない</p>
          <h3 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold py-3">「新しいもの発見型」<br className="block sm:hidden" />スタンプラリー</h3>
          <div className="w-full sm:w-4/5 justify-center flex m-auto py-10 px-1">
            <ul className={`flex-col lg:flex-row flex justify-center m-auto gap-4 text-xl`}>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/feature-01.png`} alt="" />
                <p className="text-base sm:text-lg md:text-xl">
                  <span className="text-lg sm:text-xl md:text-2xl">エリア</span>と<span className="text-lg sm:text-xl md:text-2xl">スポット</span>を<br />
                  <span className="text-lg sm:text-xl md:text-2xl">どこでも設定</span>できる
                </p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/feature-02.png`} alt="" />
                <p className="text-base sm:text-lg md:text-xl">
                  <span className="text-lg sm:text-xl md:text-2xl">写真投稿</span>＆ <span className="text-lg sm:text-xl md:text-2xl">シェア</span>で<br />
                  参加者が楽しめる
                </p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/feature-03.png`} alt="" />
                <p className="text-base sm:text-lg md:text-xl">
                  イベントの<br className="block sm:hidden" /><span className="text-lg sm:text-xl md:text-2xl">運営サポート</span>も<br />お任せください
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
  )
}

export default FeaturesInfo;