import React from "react";
import Header from "../components/block/Header";
import Footer from "../components/block/Footer";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';

const ApplicationExample = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    speed: 100,
    responsive: [
      {
        breakpoint: 1024, // 1024px以下の画面幅に対する設定
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600, // 600px以下の画面幅に対する設定
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480, // 480px以下の画面幅に対する設定
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return(
    <>
      <Helmet>
        <title>機能・活用例 | どこでもラリー</title>
      </Helmet>

      <Header />

      <section className="use-flow-title pt-24 sm:pt-44 pb-12 sm:pb-24">
        <h3 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold py-3">機能・活用例</h3>
        <p className="text-rhino-950 md:text-2xl	font-medium">ご要望に合わせて運用をカスタマイズできます</p>
      </section>

      <section className="mb-40">
        <Slider {...settings} className="slider use-flow-slider">
          <div className="slide">
            <p>
              スポット一覧
            </p>
            <img src={`${process.env.PUBLIC_URL}/images/sp_sample_1.png`} className="block" alt="..." />
          </div>
          <div className="slide">
            <p>
              スタンプ帳
            </p>
            <img src={`${process.env.PUBLIC_URL}/images/sp_sample_2.png`} className="block" alt="..." />
          </div>
          <div className="slide">
            <p>
              マップ
            </p>
            <img src={`${process.env.PUBLIC_URL}/images/sp_sample_3.png`} className="block" alt="..." />
          </div>
          <div className="slide">
            <p>
              スポット案内
            </p>
            <img src={`${process.env.PUBLIC_URL}/images/sp_sample_4.png`} className="block" alt="..." />
          </div>
          <div className="slide">
            <p>
              特典応募
            </p>
            <img src={`${process.env.PUBLIC_URL}/images/sp_sample_5.png`} className="block" alt="..." />
          </div>
        </Slider>
        <div>
          <p>アプリのダウンロードは不要！</p>
          <p className="text-rhino-950 text-xl sm:text-2xl md:text-3xl font-bold py-3">幅広い世代が参加できます</p>
        </div>
      </section>

      <section className="function-info-area">
        <section className="px-8">
          <p className="speech-bubble title">どこでもラリーの</p>
          <h4 className="text-rhino-950 text-3xl sm:text-4xl md:text-5xl font-bold py-3">主な機能</h4>
          <div className="function-info-box">
            <h5 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold pt-8 sm:pt-20 pb-4">選べるスタンプ取得方法</h5>
            <ul>
              <li className="basis-1/2">
                <img src={`${process.env.PUBLIC_URL}/images/function-info-how-to-get-stamps-1.png`} />
                <p>写真を共有するだけで、簡単にスタンプ取得。他の参加者に情報がシェアされ、周遊促進につながります。</p>
              </li>
              <li className="basis-1/2">
                <img src={`${process.env.PUBLIC_URL}/images/function-info-how-to-get-stamps-2.png`} />
                <p>従来のQRコードでのスタンプ取得も併用できます。</p>
              </li>
            </ul>
          </div>

          <div className="function-info-box">
            <h5 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold pt-20 pb-4">自由なスポット設定</h5>
            <ul>
              <li className="basis-1/2">
                <img src={`${process.env.PUBLIC_URL}/images/function-info-free-spot-setting-1.png`} />
                <p>参加者は、スポット内なら「どこでも」写真を撮ってスタンプを獲得できます。</p>
              </li>
              <li className="basis-1/2">
                <img src={`${process.env.PUBLIC_URL}/images/function-info-free-spot-setting-2.png`} />
                <p>主催側で特定のスポットを設定して、周回ルートを決めることもできます。</p>
              </li>
            </ul>
          </div>

          <div className="xl:flex w-full xl:w-4/5 mx-auto max-w-[1100px] xl:gap-16 mb-40">
            <div className="function-info-box">
              <h5>レシートキャンペーン</h5>
              <ul>
                <li className="basis-full">
                  <img src={`${process.env.PUBLIC_URL}/images/function-info-recipt-campaign-1.png`} />
                  <p>購買促進に効果的なレシートキャンペーンと、スタンプラリーの併用ができます。</p>
                </li>
              </ul>
            </div>
            <div className="function-info-box">
              <h5>ツール連携</h5>
              <ul>
                <li className="basis-full">
                  <img src={`${process.env.PUBLIC_URL}/images/function-info-connect-tools-1.png`} />
                  <p>当落がその場でわかるスクラッチツールなどと連携できます。クーポン機能のご相談も承ります。</p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="px-8">
          <p className="speech-bubble light-blue title">どこでもラリーの</p>
          <h4 className="text-rhino-950 text-3xl sm:text-4xl md:text-5xl font-bold py-3">主な運用サービス</h4>
          <div className="xl:flex w-full xl:w-4/5 mx-auto max-w-[1100px] xl:gap-16 mb-40">
            <div className="function-info-box">
              <h5>参加者データの運用</h5>
              <ul>
                <li className="basis-full">
                  <img src={`${process.env.PUBLIC_URL}/images/function-info-participant-data-operation-1.png`} />
                  <p>参加者アンケートや、人流データ、人気スポットなどの集計をサポートし、観光に役立つ情報をご提供します。</p>
                </li>
              </ul>
            </div>
            <div className="function-info-box">
              <h5>プレゼントの発送</h5>
              <ul>
                <li className="basis-full">
                  <img src={`${process.env.PUBLIC_URL}/images/function-info-send-present-1.png`} />
                  <p>プレゼント発送など、イベント運営で面倒なタスクは、当社にお任せください。</p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="other-functions">
          <p className="speech-bubble dark-blue title">どこでもラリーの</p>
          <h4 className="text-rhino-950 text-3xl sm:text-4xl md:text-5xl font-bold py-3 mb-16">その他の機能</h4>
          <dl>
            <dt>ユーザー補助機能</dt>
            <dd>
              <ul>
                <li>多言語対応</li>
                <li>スポット一覧</li>
                <li>スタンプ帳</li>
                <li>GPS機能</li>
                <li>投稿一覧</li>
                <li>プレゼント応募</li>
                <li>選べるログイン方法</li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt>管理・運用</dt>
            <dd>
              <ul>
                <li>アンケート機能</li>
                <li>ログイン＆トップ画面＆スポット案内画面のカスタマイズ </li>
                <li>管理者による投稿写真閲覧</li>
                <li>参加者情報のダウンロード</li>
                <li>スタンプ画像変更</li>
                <li>プライバシーポリシー同意機能</li>
                <li>スタンプ獲得者の管理</li>
                <li>プレゼント条件設定</li>
                <li>投稿写真の検閲機能</li>
                <li>特典応募者の管理</li>
                <li>LP転載用のHTML出力</li>
              </ul>
            </dd>
          </dl>
        </section>
        <div className="absolute -bottom-12 left-[calc((100%-5px)/2)] z-10">
          <img src={`${process.env.PUBLIC_URL}/images/three-dots.png`} alt="" />
        </div>
      </section>

      <section className="example-area">
        <section className="use-flow-title sm:pt-8 md:pt-44 pb-24 px-8 sm:px-4">
          <h4 className="text-rhino-950 text-3xl sm:text-4xl md:text-5xl font-bold py-3 mb-16">例えばこんな<br className="block sm:hidden" />キャンペーンが<br className="block sm:hidden" />できます</h4>
          <div className="example-box">
            <h5>写真投稿型スタンプラリー</h5>
            <p>どこでもスポットの設定が可能なので、広域でのキャンペーンが実施できます。現地にQRコードなどの設置も不要で、運営がスムーズ（QRコード型との併用も可能）。</p>
            <ul>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-photo-submission-stamp-rally-1.png`} alt="" />
                <p>キャンペーンエリア内でお気に入りの写真を撮影</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-photo-submission-stamp-rally-2.png`} alt="" />
                <p>写真を投稿してスタンプGET</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-photo-submission-stamp-rally-3.png`} alt="" />
                <p>スタンプを集めてプレゼントに応募</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-photo-submission-stamp-rally-4.png`} alt="" />
                <p>抽選でプレゼントが当たる</p>
              </li>
            </ul>
          </div>
          <div className="example-box">
            <h5>QRコード型スタンプラリー</h5>
            <p>対象スポットにQRコードを設置し参加者はQRコードを読み取ることでスタンプを獲得！写真投稿と併用も可能なため撮影不可のスポットでもキャンペーン実施が可能です！</p>
            <ul>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-qr-type-stamp-rally-1.png`} alt="" />
                <p>スポット情報から対象の施設を確認</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-qr-type-stamp-rally-2.png`} alt="" />
                <p>対象の施設を訪れる</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-qr-type-stamp-rally-3.png`} alt="" />
                <p>設置されたQRコードを読みとりスタンプGET</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-qr-type-stamp-rally-4.png`} alt="" />
                <p>必要数のスタンプを集めてプレゼントに応募</p>
              </li>
            </ul>
          </div>
          <div className="example-box">
            <h5>レシートキャンペーン</h5>
            <p>対象地域の店舗でお買い物をしてレシート画像を撮影することで、マストバイキャンペーンの実施も可能！獲得スタンプ数に応じてプレゼントに応募できます。</p>
            <ul>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-recipt-campaign-1.png`} alt="" />
                <p>対象のお店で食事や買い物をする</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-recipt-campaign-2.png`} alt="" />
                <p>レシート画像を撮ってスタンプGET<br />※レシート画像は共有されません</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-recipt-campaign-3.png`} alt="" />
                <p>選択したプレゼントが抽選で当たる</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-recipt-campaign-4.png`} alt="" />
                <p>必要数のスタンプを集めてプレゼントに応募</p>
              </li>
            </ul>
          </div>
          <div className="example-box">
            <h5>スクラッチキャンペーン</h5>
            <p>エリア内でスタンプラリーに参加し、必要数のスタンプを獲得するとインスタントくじに挑戦できます。</p>
            <ul>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-scratch-campaign-1.png`} alt="" />
                <p>エリア内でお買い物ついでにスタンプラリーに参加</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-scratch-campaign-2.png`} alt="" />
                <p>お買い物中の映え写真を投稿してスタンプ獲得</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-scratch-campaign-3.png`} alt="" />
                <p>必要数のスタンプを獲得してスクラッチに挑戦！</p>
              </li>
              <li>
                <img src={`${process.env.PUBLIC_URL}/images/example-scratch-campaign-4.png`} alt="" />
                <p>当たり！<br />プレゼントをGET</p>
              </li>
            </ul>
          </div>
        </section>
        <section className="notice">
          <p>ご希望のキャンペーンにあわせて、さまざまな機能をカスタマイズできます。<br />まずはご相談のみでも、お気軽にお問い合わせください。</p>
        </section>
      </section>
      <Footer />
    </>
  )
}

export default ApplicationExample;