import React from "react";

const MainVisual = () => {
  return(
    <section id="main-visual">
        <div className="w-4/5 lg:w-full md-full justify-center items-start lg:grid lg:grid-cols-6 gap-4 m-auto sm:py-10 lg:py-40 lg:px-20">
            <div className="col-stat-1 col-span-2 row-span-1">
                <h2 className="lg:mb-32 lg:w-full text-center mx-auto lg:text-left lg:mx-0">
                    <img className="hidden lg:block" src={`${process.env.PUBLIC_URL}/images/copy-01.svg`} alt="地域の魅力、再発見！写真でつながる観光スタンプラリー" />
                    <img className="block lg:hidden w-full sm:w-4/5 mx-auto pt-10" src={`${process.env.PUBLIC_URL}/images/copy-01-sp.png`} alt="地域の魅力、再発見！写真でつながる観光スタンプラリー" />
                </h2>
            </div>
            <div className="col-stat-5 col-span-3 row-span-3">
                <img src={`${process.env.PUBLIC_URL}/images/main-visual-illust.png`} alt="" />
            </div>
            <div className="col-stat-1 col-span-2 row-span-1 lg:pl-8">
                <div className="intro-box text-center mx-auto lg:text-left lg:mx-0">
                    <img className="hidden lg:block" src={`${process.env.PUBLIC_URL}/images/copy-02.svg`} alt="どこでもラリーは「地域の隠れた魅力」を広め、「新たな観光資源」を見つけるお手伝いをします。" />
                    <img className="block lg:hidden w-full sm:w-4/5 sm:w-3/5 mx-auto pt-5 pb-20" src={`${process.env.PUBLIC_URL}/images/copy-02-sp.png`} alt="どこでもラリーは「地域の隠れた魅力」を広め、「新たな観光資源」を見つけるお手伝いをします。" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default MainVisual;