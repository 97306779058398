import React from "react";
import Header from "../components/block/Header";
import Footer from "../components/block/Footer";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

const UseFlow = () => {
  return(
    <>
      <Helmet>
        <title>ご利用の流れ | どこでもラリー</title>
      </Helmet>
      
      <Header />

      <section className="use-flow-title pt-24 sm:pt-44 pb-12 sm:pb-24">
        <h3 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold py-3">ご利用の流れ</h3>
        <p className="text-rhino-950 md:text-2xl	font-medium">どこでもラリー導入までの流れをご説明します</p>
      </section>
      <section className="use-flow-body">
        <ul className="use-flow-steps">
          <li>
            <img src={`${process.env.PUBLIC_URL}/images/icon-use-flow-contact.png`} alt="" />
            <dl>
              <dt>お問い合わせ</dt>
              <dd>
              お問い合わせフォームからお問い合わせいただきましたら、翌営業日以内にご連絡いたします。<br />
              <Link to="https://galileoscope.co.jp/contact">お問い合わせはこちら ></Link>
              </dd>
            </dl>
          </li>
          <li>
            <img src={`${process.env.PUBLIC_URL}/images/icon-use-flow-consultation.png`} alt="" />
            <dl>
              <dt>ご相談</dt>
              <dd>
                ご希望のキャンペーン内容を詳しくお伺いいたします。
              </dd>
            </dl>
          </li>
          <li>
            <img src={`${process.env.PUBLIC_URL}/images/icon-use-flow-estimate.png`} alt="" />
            <dl>
              <dt>お見積り</dt>
              <dd>
                お見積りに問題がなければ、お申込み書を作成いたします。
              </dd>
            </dl>
          </li>
          <li>
            <img src={`${process.env.PUBLIC_URL}/images/icon-use-flow-apply.png`} alt="" />
            <dl>
              <dt>お申込み</dt>
              <dd>
                ご希望のキャンペーン内容を詳しくお伺いいたします。
              </dd>
            </dl>
          </li>
          <li>
            <img src={`${process.env.PUBLIC_URL}/images/icon-use-flow-system-setting.png`} alt="" />
            <dl>
              <dt>システム設定<br /><span className="sub">（約３週間）</span></dt>
              <dd>
                設定は当社で行います。ご相談いただきながらオーダーメイドのキャンペーンを作成いたします。
              </dd>
            </dl>
          </li>
          <li>
            <img src={`${process.env.PUBLIC_URL}/images/icon-use-flow-operation.png`} alt="" />
            <dl>
              <dt>運用開始</dt>
              <dd>
                運用後のキャンペーン事務局はオプションにて対応可能です。
              </dd>
            </dl>
          </li>
          <li>
            <img src={`${process.env.PUBLIC_URL}/images/icon-use-flow-payment.png`} alt="" />
            <dl>
              <dt>お支払い</dt>
              <dd>
                キャンペーン開始月にお支払いいただきます。
              </dd>
            </dl>
          </li>
        </ul>
      </section>

      <Footer />
    </>
  )
}

export default UseFlow;