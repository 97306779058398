import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SliderArea = () => {

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    centerMode: true,
    speed: 100,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 680, // 480px以下の画面幅に対する設定
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return(
    <section className="slider-area">
      <div className="w-4/5 mx-auto">
        <p className="text-rhino-950 md:text-2xl	font-medium">写真投稿＆シェア機能で</p>
        <h3 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold py-3">街の魅力を発見・広めよう</h3>
      </div>
      <div className="w-full sm:w-4/5 justify-center m-auto py-10">
        <Slider {...settings} className="slider">
          <div className="slide">
            <img src={`${process.env.PUBLIC_URL}/images/carousel-1.png`} className="block" alt="..." />
            <p>
              参加者は、エリア内の好きなスポットで自由に写真を撮って、スタンプを取得できます。<br />写真のシェア機能で、他の参加者におすすめのスポット情報が共有され、「行ってみたい場所」が増える仕組みです。
            </p>
          </div>
          <div className="slide">
            <img src={`${process.env.PUBLIC_URL}/images/carousel-2.png`} className="block" alt="..." />
            <p>
              スタンプラリーと並行して、<br />
              お得な割引券などが当たる「レシートキャンペーン」も実施が可能。<br />
              対象の店舗でお買い物をして、レシート画像を投稿するだけ！
            </p>
          </div>
          <div className="slide">
            <img src={`${process.env.PUBLIC_URL}/images/carousel-3.png`} className="block" alt="..." />
            <p>
              参加者情報の管理、プレゼント発送など、<br />
              イベント運営のお困りごとは当社にご相談ください。
            </p>
          </div>
        </Slider>


        </div>
      </section>
  )
}

export default SliderArea;