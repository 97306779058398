import React from "react";
import Header from "../components/block/Header";
import Footer from "../components/block/Footer";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';


const Faq = () => {
  return(
    <>
      <Helmet>
        <title>よくあるご質問 | どこでもラリー</title>
      </Helmet>
      <Header />

      <section className="faq-title pt-24 sm:pt-44 pb-12 sm:pb-24">
        <h3 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold py-3">よくあるご質問</h3>
        <p className="text-rhino-950 md:text-2xl	font-medium">よくお寄せいただく質問と回答をご紹介します</p>
      </section>
      <section className="faq-body">
        <section className="faq-box">
          <h4>キャンペーン参加について</h4>
          <ul>
            <li>
              <dl>
                <dt className="q">WEBブラウザ版ですか？  アプリ版ですか？</dt>
                <dd className="a">
                お問い合わせフォームからお問い合わせいただきましたら、翌営業日以内にご連絡いたします。<br />
                <Link to="https://galileoscope.co.jp/contact">お問い合わせはこちら ></Link>
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="q">参加登録方法にはどのような種類がありますか？</dt>
                <dd className="a">
                Googleアカウント・メールアドレス・電話番号からログイン方法をお選びいただけます。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="q">対応機種を教えてください</dt>
                <dd className="a">
                対応機種はiOS 15.0以上 Safari、Chrome最新ブラウザ/Android 8.0以上 Chrome 最新ブラウザです。<br />
                パソコン、フィーチャーフォンには対応しておりません。
                </dd>
              </dl>
            </li>
          </ul>
        </section>

        <section className="faq-box">
          <h4>運営・サポートについて</h4>
          <ul>
            <li>
              <dl>
                <dt className="q">投稿データはどのように保存されますか？</dt>
                <dd className="a">
                管理者画面でいつでも閲覧が可能です。検閲機能がございますので不適切な写真は非公開に設定できます。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="q">取得できるデータを教えてください</dt>
                <dd className="a">
                参加者データ／参加者の周遊データ／アンケート集計結果／投稿写真／スポットへの訪問回数／プレゼント応募者データ
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="q">スポット数に制限はありますか？</dt>
                <dd className="a">
                スポット数に制限はございません。
                </dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt className="q">投稿写真を外部サイトに利用できますか？</dt>
                <dd className="a">
                可能です。
                </dd>
              </dl>
            </li>
          </ul>
        </section>

        <div className="notice">
          <p>
            こちらに記載の無い内容についても、お気軽にお問い合わせください。<br />
            ご導入を本格的にご検討の方から情報収集段階の方まで、幅広くお問い合わせをいただいております。
          </p>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Faq;