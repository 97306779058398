import React from "react";
import Header from "../components/block/Header";
import MainVisual from "../components/block/MainVisual";
import FeaturesInfo from "../components/block/FeaturesInfo";
import UsecaseInfo from "../components/block/UsecaseInfo";
import SupportInfo from "../components/block/SupportInfo";
import SliderArea from "../components/block/SliderArea";
import ServiceNavigation from "../components/block/ServiceNavigation";
import Footer from "../components/block/Footer";
import { Helmet } from 'react-helmet-async';

const Top = () => {
  return(
    <>
      <Helmet>
        <title>どこでもラリー</title>
      </Helmet>
      <Header />
      <MainVisual />
      <FeaturesInfo />
      <UsecaseInfo />
      <SupportInfo />
      <SliderArea />
      <ServiceNavigation />
      <Footer />
    </>
  )
}

export default Top;