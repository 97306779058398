import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return(
    <>
      <section className="contact-area sm:flex sm:flex-row items-center bg-slate-700 text-white py-20 justify-center">
        <div className="basis-full sm:basis-1/2 lg:basis-1/3 2xl:basis-1/4">
          <p className="message">
            キャンペーン運用のノウハウを生かして<br />
            導入〜運用までしっかりサポート！<br />
            お気軽にお問い合わせ・ご相談ください。
          </p>
        </div>
        <div className="basis-full sm:basis-1/2 lg:basis-1/3 2xl:basis-1/4 mt-8">
        <Link to="https://galileoscope.co.jp/contact" className="contact-button">資料請求・お問い合わせ</Link>
        </div>
      </section>
      <footer className="pt-8 pb-20 bg-neutral-100 text-center">
        <nav className="border-gray-200 mx-auto lg:w-4/5">
          <div className="max-w-screen-xl flex flex-col-reverse	lg:flex-row lg:flex-wrap items-center justify-between mx-auto p-4">
            <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
              <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" className="logo" />
            </a>
            <div className="w-full md:block md:w-auto" id="navbar-default">
              <ul className="text-lg flex flex-col p-4 md:p-0 mt-4 lg:mt-4 mb-8 lg:mb-0 border border-gray-100 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 leading-10 lg:leading-none">
                <li><Link to="/application-example">機能・活用例</Link></li>
                <li><Link to="/useflow">ご利用の流れ</Link></li>
                <li><Link to="/faq">よくあるご質問</Link></li>
                <li><Link to="https://galileoscope.co.jp/contact">お問い合わせ</Link></li>
                <li><Link to="https://galileoscope.co.jp/about">運営会社</Link></li>
              </ul>
            </div>
          </div>
        </nav>
        <address className="py-12 text-slate-700 not-italic">
          © どこでもラリー 2024　All Rights Reserved.
        </address>
      </footer>
    </>

  )
}

export default Footer;