import React from "react";
import { Link } from "react-router-dom";

const SupportInfo = () => {
  
  return(
    <section className="support-info">
      <div className="w-4/5 mx-auto">
        <p className="text-rhino-950 md:text-2xl	font-medium">情報共有型スタンプラリーで</p>
        <h3 className="text-rhino-950 text-2xl sm:text-3xl md:text-4xl font-bold py-3">「周遊促進」<span className="text-xl">と</span><br className="block sm:hidden" />「観光資源の発見」<span className="text-xl">を<br className="block sm:hidden" />サポートします</span></h3>
        <div className="w-4/5 md-full justify-center m-auto py-10">
          <ul className={`text-left`}>
            <li>
              <div>
                <h4>宝探し気分で、<br />楽しくスタンプラリー</h4>
                <p>
                スポット内なら「どこでも」写真撮影してスタンプを獲得！地域にどんな魅力が隠れているか、見つける楽しさを味わえます。<br />地域の魅力スポットを、クイズに挑戦しながら巡ってもらう「謎解きイベント」も開催できます。
                </p>
              </div>
              <img src={`${process.env.PUBLIC_URL}/images/support-01.png`} alt="" />
            </li>
            <li className="flex-row-reverse">
              <div>
                <h4>参加者同士で<br className="block sm:hidden" />スポットの写真<br />をシェアして、<br className="block sm:hidden" />魅力が広まる</h4>
                <p>
                  写真を撮るだけで、簡単にスタンプを獲得。知られていない新しい観光資源がシェアされ、集客につながります。
                </p>
              </div>
              <img src={`${process.env.PUBLIC_URL}/images/support-02.png`} alt="" />
            </li>
            <li>
              <div>
                <h4>プレゼント発送、<br className="block sm:hidden" />人流データの<br />分析サポートなど<br className="block sm:hidden" />運用サービス<br className="hidden sm:block" />が充実</h4>
                <p>
                  スタンプラリーの導入〜運用まで、キャンペーンのプロにまるっとお任せ！
                </p>
              </div>
              <img src={`${process.env.PUBLIC_URL}/images/support-03.png`} alt="" />
            </li>
          </ul>
          <Link to="/application-example">
            <button type="button" className="button">その他の機能を詳しく見る</button>
          </Link>
        </div>
      </div>
    </section>
  )
}

export default SupportInfo;